'use client'

import Font from '@/fonts/font'
import Link from 'next/link'
import useScrolling from '@/lib/hooks/useScrolling'
import getLocale from '@/lib/utilities/getLocale'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

export default function CategoryContent({ category, settings, text }) {
  const locale = getLocale(text)
  const {
    containerRef,
    itemsRef,
    firstVisible,
    lastVisible,
    scrollPrev,
    scrollNext
  } = useScrolling([category.length])

  return (
    <div className="w-full relative max-w-[60rem] m-auto">
      {!firstVisible && (
        <div
          className="absolute top-0 left-0 h-full flex items-center z-10"
          onClick={() => {
            scrollPrev()
          }}
        >
          <LeftCircleOutlined
            className="text-3xl rounded-full"
            style={{ background: 'rgba(255,255,255,.7)', color: '#606060' }}
          />
        </div>
      )}
      <div
        className="flex mt-12 md:mt-24 justify-start md:justify-center overflow-x-auto mx-3 md:mx-0 flex-nowrap md:flex-wrap"
        ref={containerRef}
      >
        {category.map((c, index) => {
          return (
            <Link
              key={`category-${index}`}
              className={`basis-1/3 flex flex-col justify-center items-center mx-3 md:mx-0 my-6`}
              href={c.link}
              title={locale[c.text]}
            >
              <div
                className={`rounded-xl w-24 h-24 md:w-48 md:h-48 bg-cover bg-no-repeat bg-center`}
                key={`category-${index}`}
                style={{
                  color: '#606060',
                  backgroundImage: `url("${settings.cdn}${c.thumbnail}`
                }}
              ></div>
              <span
                className={`${Font.Myriad} font-bold text-md md:text-lg mt-3 whitespace-nowrap text-white md:text-[#606060]`}
                ref={e => {
                  itemsRef.current[`category-${index}`] = e
                }}
              >
                {locale[c.text]}
              </span>
            </Link>
          )
        })}
      </div>
      {!lastVisible && (
        <div
          className="absolute top-0 right-0 h-full flex items-center z-10"
          onClick={() => {
            scrollNext()
          }}
        >
          <RightCircleOutlined
            className="text-3xl rounded-full"
            style={{ background: 'rgba(255,255,255,.7)', color: '#606060' }}
          />
        </div>
      )}
    </div>
  )
}
